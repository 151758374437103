import React, { useContext, useRef, FunctionComponent } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConfigContext from "../components/ConfigContext";
import { buildURL } from "../utils";
import { RootState, AuthProvider } from "../reducers/types";
import { logout } from "../actions/logout";
import { getKeycloak } from "../utils/keycloack";
import { RouteComponentProps } from "react-router-dom";

const Logout: FunctionComponent<RouteComponentProps> = () => {
  const keycloak = getKeycloak();
  const dispatch = useDispatch();
  const authProvider = useSelector(
    (state: RootState) => state.config.authProvider
  );
  const authentication = useSelector(
    (state: RootState) => state.authentication
  );
  const config = useContext(ConfigContext);

  // to actually log out the user from adfs; we need to pass the idToken to the logout endpoint as a querystring parameter.
  // When we call the Logout function; the idToken gets destroyed so we need to fetch it before actually logging out.
  const idToken = useRef(authentication.token?.idToken);

  // Wait for the loggedOut event to be properly processed before navigating away from the page. Without the wait for
  // loggedIn to become false, any extra actions that the reducer might take can be cut short.
  if (authentication.loggedIn) {
    dispatch(logout());
  } else if (authProvider === AuthProvider.Adfs) {
    // redirect the user to adfs
    window.location.href = buildURL(config.staterOAuthValues.logOutUrl, {
      id_token: idToken,
    });
  } else if (
    authProvider === AuthProvider.Keycloak &&
    keycloak?.authenticated === true
  ) {
    keycloak.logout();
  }

  return <></>;
};

export default Logout;
