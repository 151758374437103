import { PasswordResetInformation } from "./types";
import {
  VERIFICATION_NUMBER_BY_USERNAME_ERROR,
  VERIFICATION_NUMBER_BY_USERNAME_VALIDATED,
  PASSWORD_RESET_DONE,
  PASSWORD_RESET_ERROR,
  SEND_VERIFICATION_NUMBER_BY_USERNAME_SUCCESSFULL,
  SEND_VERIFICATION_NUMBER_BY_USERNAME_ERROR,
  PASSWORD_RESET_INPUT_CHANGED,
  VERIFICATION_NUMBER_BY_USERNAME_VALIDATING,
  PASSWORD_RESET_INPROGRESS,
} from "../constants/actionTypes";
import { AnyAction } from "redux";
import { HandleServerErrorResponse } from "../utils/errorHandler";

const initialState: PasswordResetInformation = {
  user: null,
  error: null,
  verificationNumberValidating: false,
  verificationNumberValidated: false,
  verificationNumberSentSuccessfull: false,
  verificationNumberSending: false,
  usernameValidated: false,
  usernameSent: false,
  passwordResetting: false,
  passwordResetDone: false,
  flowStep: 0,
  isInternal: null
};

export default (
  state: PasswordResetInformation = initialState,
  action: AnyAction
): PasswordResetInformation => {
  switch (action.type) {
    case PASSWORD_RESET_INPUT_CHANGED:
      return {
        ...state,
        error: {},
      };
    case VERIFICATION_NUMBER_BY_USERNAME_VALIDATING:
      return {
        ...state,
        error: {},
        verificationNumberValidating: true,
      };
    case VERIFICATION_NUMBER_BY_USERNAME_VALIDATED:
      return {
        ...state,
        verificationNumberValidated: true,
        verificationNumberValidating: false,
        flowStep: 2,
      };
    case VERIFICATION_NUMBER_BY_USERNAME_ERROR:
      return {
        ...state,
        error: HandleServerErrorResponse(action, { 401: "ERR-PWD-06" }),
        verificationNumberValidating: false,
      };
    case PASSWORD_RESET_INPROGRESS:
      return {
        ...state,
        error: {},
        passwordResetting: true,
      };
    case PASSWORD_RESET_ERROR:
      return {
        ...state,
        error: {
          error: action.payload.error,
          showGenericError: action.payload.errorCodes ? false : true,
          errorCodes: action.payload.errorCodes,
        },
        passwordResetting: false,
      };
    case PASSWORD_RESET_DONE:
      return {
        ...state,
        passwordResetDone: true,
        passwordResetting: false,
        flowStep: 3,
      };

    case SEND_VERIFICATION_NUMBER_BY_USERNAME_ERROR:
      return {
        ...state,
        error: HandleServerErrorResponse(action, { 404: "ERR-PWD-06" }),
        verificationNumberSending: false,
      };
    case SEND_VERIFICATION_NUMBER_BY_USERNAME_SUCCESSFULL:
      return {
        ...state,
        verificationNumberSentSuccessfull: true,
        verificationNumberSending: false,
        isInternal: action.payload.isInternal,
        flowStep: 1,
      };
    default:
      return state;
  }
};
