export const pwdErrorCodes = {
  notValid: "ERR-PWD-01",
  notEqual: "ERR-PWD-02",
  userNotFound: "ERR-PWD-03",
  usedBefore: "ERR-PWD-04",
  generic: "ERR-PWD-05",
  verificationNotCorrect: "ERR-PWD-06",
  noEmailaddress: "No EmailAddress",
  noPhoneNumber: "No PhoneNumber",
};
