import { createContext } from "react";

interface ConfigOAuthValues {
  logOutUrl: string;
  authorizeUrl: string;
  tokenUrl: string;
  clientId: string;
  clientSecret: string;
  resource: string;
  resourceTotp: string;
}

interface OpenIdConnectValues {
  logOutUrl: string;
  authorizeUrl: string;
  clientId: string;
}

interface googleTagValues {
  gtmId: string;
}

enum AuthProvider {
  Adfs = "ADFS",
  Keycloak = "Keycloak",
}

interface Config {
  basePath: string;
  api: string;
  authProvider: AuthProvider;
  staterOAuthValues: ConfigOAuthValues;
  staterOpenIdConnectValues: OpenIdConnectValues;
  googleTagManager: googleTagValues;
}

const defaultConfig: Config = {
  basePath: "",
  api: "",
  authProvider: AuthProvider.Adfs,
  staterOAuthValues: {
    logOutUrl: "",
    authorizeUrl: "",
    tokenUrl: "",
    clientId: "",
    clientSecret: "",
    resource: "",
    resourceTotp: "",
  },
  staterOpenIdConnectValues: {
    logOutUrl: "",
    authorizeUrl: "",
    clientId: "",
  },
  googleTagManager: {
    gtmId: "",
  },
};

const ConfigContext = createContext<Config>(defaultConfig);
export default ConfigContext;
