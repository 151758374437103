import { Authentication, RootState } from "../reducers/types";

const authKey = "auth";

export const loadAuthState = (): Authentication | undefined => {
  try {
    const serializedAuthState = localStorage.getItem(authKey);

    if (serializedAuthState === null) {
      return undefined;
    }
    return JSON.parse(serializedAuthState);
  } catch {
    return undefined;
  }
};

export const saveState = (state: RootState) => {
  try {
    const serializedState = JSON.stringify(state.authentication);
    localStorage.setItem(authKey, serializedState);
  } catch {
    // ignore write errors
  }
};
