import { TotpInformation } from "./types";
import {
  SECRET_GENERATING,
  SECRET_ERROR,
  SECRET_GENERATED,
  VERIFICATION_NUMBER_VALIDATING,
  VERIFICATION_NUMBER_VALIDATED,
  VERIFICATION_NUMBER_ERROR,
  TOTP_NUMBER_VALIDATING,
  TOTP_NUMBER_ERROR,
  TOTP_NUMBER_VALIDATED,
  SEND_VERIFICATION_NUMBER_SUCCESSFULL,
  HAS_ACTIVE_TOKEN,
  HAS_ACTIVE_TOKEN_ERROR,
  HAS_ACTIVE_TOKEN_LOADING,
  CONFIRM_TOTP_OVERWRITE,
  SEND_VERIFICATION_NUMBER_ERROR,
  SEND_VERIFICATION_NUMBER_INPROGRESS,
  TOTP_TOKEN_MANAGEMENT_INPUT_CHANGED,
} from "../constants/actionTypes";
import { AnyAction } from "redux";
import { HandleServerErrorResponse } from "../utils/errorHandler";

const initialState: TotpInformation = {
  error: null,
  flowStep: null,
  hasActiveToken: null,
  hasActiveTokenError: false,
  hasActiveTokenLoading: false,
  qrCode: null,
  qrCodeLoading: false,
  secretGenerating: false,
  totpNumberValidated: false,
  totpNumberValidating: false,
  verificationNumberSending: false,
  verificationNumberSentSuccessfull: false,
  verificationNumberValidated: false,
  verificationNumberValidating: false,
};

export default (
  state: TotpInformation = initialState,
  action: AnyAction
): TotpInformation => {
  switch (action.type) {
    case TOTP_TOKEN_MANAGEMENT_INPUT_CHANGED:
      return {
        ...state,
        error: {},
      };
    case CONFIRM_TOTP_OVERWRITE:
      return {
        ...state,
        flowStep: 1,
      };
    case TOTP_NUMBER_VALIDATING:
      return {
        ...state,
        error: {},
        totpNumberValidating: true,
      };
    case TOTP_NUMBER_VALIDATED:
      return {
        ...state,
        totpNumberValidated: true,
        totpNumberValidating: false,
        flowStep: 4,
      };
    case TOTP_NUMBER_ERROR:
      return {
        ...state,
        error: HandleError(action, "status code 401", "ERR-TTM-01"),
        totpNumberValidating: false,
      };
    case SECRET_GENERATING:
      return {
        ...state,
        secretGenerating: true,
      };
    case SECRET_ERROR:
      return {
        ...state,
        error: {
          error: action.payload.error,
          showGenericError: action.payload.errorCodes ? false : true,
          errorCodes: action.payload.errorCodes,
        },
        qrCodeLoading: false,
      };
    case SECRET_GENERATED:
      return {
        ...state,
        qrCode: action.payload.content,
        qrCodeLoading: false,
      };
    case VERIFICATION_NUMBER_VALIDATING:
      return {
        ...state,
        error: {},
        verificationNumberValidating: true,
      };
    case VERIFICATION_NUMBER_VALIDATED:
      return {
        ...state,
        verificationNumberValidated: true,
        verificationNumberValidating: false,
        flowStep: 3,
      };
    case VERIFICATION_NUMBER_ERROR:
      return {
        ...state,
        error: HandleError(action, "status code 401", "ERR-PWD-06"),
        verificationNumberValidating: false,
      };
    case SEND_VERIFICATION_NUMBER_INPROGRESS:
      return {
        ...state,
        verificationNumberSentSuccessfull: false,
        verificationNumberSending: true,
      };
    case SEND_VERIFICATION_NUMBER_SUCCESSFULL:
      return {
        ...state,
        verificationNumberSentSuccessfull: true,
        verificationNumberSending: false,
        flowStep: 2,
      };
    case SEND_VERIFICATION_NUMBER_ERROR:
      return {
        ...state,
        error: HandleServerErrorResponse(action, {404: "ERR-TMM-03"}),
        verificationNumberSentSuccessfull: false,
        verificationNumberSending: false,
      };
    case HAS_ACTIVE_TOKEN:
      return {
        ...state,
        hasActiveToken: action.payload.hasToken,
        flowStep: action.payload.hasToken ? 0 : 1,
        hasActiveTokenLoading: false,
        hasActiveTokenError: false,
      };
    case HAS_ACTIVE_TOKEN_ERROR:
      return {
        ...state,
        error: HandleHasActiveTokenError(action),
        hasActiveTokenLoading: false,
        hasActiveTokenError: true,
      };
    case HAS_ACTIVE_TOKEN_LOADING:
      return {
        ...state,
        hasActiveTokenLoading: true,
        hasActiveTokenError: false,
      };
    default:
      return state;
  }
};

const HandleError = (action: AnyAction, error: string, errorCode: string) => {
  let errorCodes = action?.payload?.errorCodes;
  let showGenericError = true;
  if (action?.payload?.error?.message?.indexOf(error) > -1) {
    errorCodes = [errorCode];
    showGenericError = false;
  }

  if (action?.payload?.error?.response?.data?.error != null) {
    errorCodes = [action?.payload?.error?.response?.data?.error];
    showGenericError = false;
  }

  return {
    error: action.payload.error,
    showGenericError: showGenericError,
    errorCodes: errorCodes,
  };
};

const HandleHasActiveTokenError = (action: AnyAction) => {
  return {
    error: action.payload.error,
    showGenericError: true,
    errorCodes: [],
  };
};
