import React, { FunctionComponent } from "react";
import ConfigContext from "./ConfigContext";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../reducers/types";
import { thunkedConfigRetrieved } from "../actions/getConfig";

const ConfigWrapper: FunctionComponent = ({ children }) => {
  const config = useSelector((state: RootState) => state.config);
  const dispatch = useDispatch();
  if (!config.isLoaded) {
    dispatch(thunkedConfigRetrieved());
  }

  return config.isLoaded ? (
    <ConfigContext.Provider value={config}>
      {children}
    </ConfigContext.Provider>
  ) : null;
};

export default ConfigWrapper;
