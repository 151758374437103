import { Authentication } from "./types";
import { AnyAction } from "redux";
import { TOKEN_RETRIEVED, LOGOUT } from "../constants/actionTypes";

const initialAuthentication: Authentication = {
  token: undefined,
  loggedIn: false,
};

export default (
  state = initialAuthentication,
  action: AnyAction
): Authentication => {
  switch (action.type) {
    case TOKEN_RETRIEVED: {
      return {
        token: action.payload.token,
        loggedIn: true,
      };
    }   
    case LOGOUT: {
      return { ...state, token: undefined, loggedIn: false };
    }
    default:
      return state;
  }
};
