import {
  SEND_VERIFICATION_NUMBER_BY_USERNAME_SUCCESSFULL,
  SEND_VERIFICATION_NUMBER_BY_USERNAME_ERROR,
  SEND_VERIFICATION_NUMBER_BY_USERNAME_INPROGRESS,
} from "../constants/actionTypes";
import { Dispatch } from "redux";
import { RootState } from "../reducers/types";
import axios from "../utils/api";
import { AxiosResponse } from "axios";

interface SendVerificationNumberByUsernameResposne {  
  isInternal: boolean;
}

export const verificationNumberByUsernameSentSuccessfull = (
  isInternal: boolean
) => {
  return {
    type: SEND_VERIFICATION_NUMBER_BY_USERNAME_SUCCESSFULL,
    payload: { isInternal },
  };
};

export const verificationNumberByUsernameSentInprogress = () => {
  return {
    type: SEND_VERIFICATION_NUMBER_BY_USERNAME_INPROGRESS,
  };
};

export const verificationNumberByUsernameError = (
  error: any,
  errorResponse: AxiosResponse
) => {
  return {
    type: SEND_VERIFICATION_NUMBER_BY_USERNAME_ERROR,
    payload: { error, errorResponse },
  };
};

export const thunkedVerificationNumberSentByUsername =
  (username: string | null) =>
  // action creator, when invoked…
  (
    dispatch: Dispatch, // …returns thunk; when invoked with `dispatch`…
    getState: () => RootState
  ) => {
    dispatch(verificationNumberByUsernameSentInprogress());
    const accessToken = getState().authentication.token?.accessToken;
    const config = getState().config;
    const url = `${config.api}${config.endPoints.user.sendVerificationNumberByUsername}`;

    axios
      .post<SendVerificationNumberByUsernameResposne>(
        url,
        { username },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          dispatch(verificationNumberByUsernameSentSuccessfull(res.data.isInternal));
        }
      })
      .catch((err) => {
        dispatch(verificationNumberByUsernameError(err, err.response));
      });
  };
