import React, { FunctionComponent } from "react";
import { RootState, KeycloakInitState, AuthProvider } from "../reducers/types";
import { useDispatch, useSelector } from "react-redux";
import { thunkedKeycloakInit } from "../actions/keycloakInit";

const initOptions = {
  url: "https://keycloak-poc.so.stater.com/auth",
  realm: "stater",
  clientId: "stater-portal",
  checkLoginIframe: false,
};

const KeycloakWrapper: FunctionComponent = ({ children }) => {
  const authProvider = useSelector(
    (state: RootState) => state.config.authProvider
  );
  const keycloak = useSelector((state: RootState) => state.keycloak);
  const dispatch = useDispatch();

  if (authProvider !== AuthProvider.Keycloak) {
    return <>{children}</>;
  }

  if (keycloak.state === KeycloakInitState.NotInitialized) {
    dispatch(thunkedKeycloakInit(initOptions));
  }

  return keycloak.state === KeycloakInitState.Initialized ? (
    <>{children}</>
  ) : null;
};

export { KeycloakWrapper as default };
