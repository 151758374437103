import { Dispatch } from "redux";
import { RootState, KeycloakInitState } from "../reducers/types";
import { setKeycloak, getKeycloak } from "../utils/keycloack";
import Keycloak from "keycloak-js";
import {
  KEYCLOAK_INIT_START,
  KEYCLOAK_INIT_DONE,
} from "../constants/actionTypes";

export const keycloakInitStart = () => {
  return {
    type: KEYCLOAK_INIT_START,
  };
};

export const keycloakInitDone = () => {
  return {
    type: KEYCLOAK_INIT_DONE,
  };
};

// Look, no store import!

export const thunkedKeycloakInit = (initOptions: Keycloak.KeycloakConfig) =>
  // action creator, when invoked…
  (
    dispatch: Dispatch, // …returns thunk; when invoked with `dispatch`…
    getState: () => RootState
  ) => {    
    if (getState().keycloak.state === KeycloakInitState.NotInitialized) {
      dispatch(keycloakInitStart());  
      setKeycloak(Keycloak(initOptions));
    }

    const keycloak = getKeycloak();
    if (!keycloak) {
      return;
    }
    
    keycloak.init({ onLoad: undefined }).then(() => {
      dispatch(keycloakInitDone());
    });
  };
