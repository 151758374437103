import { CONFIG_RETRIEVED } from "../constants/actionTypes";
import { Dispatch } from "redux";
import { Config } from "../reducers/types";
import axios from "../utils/api";

// in an action creator module:
export const configRetrieved = (config: Config) => ({
  type: CONFIG_RETRIEVED,
  payload: { config },
});

// Look, no store import!

export const thunkedConfigRetrieved = () =>
  // action creator, when invoked…
  (
    dispatch: Dispatch // …returns thunk; when invoked with `dispatch`…
  ) => {
    const url =
      process.env.NODE_ENV !== "development"
        ? "./config.json"
        : "./config.dev.json";

    axios.request({ url, method: "GET" }) // …performs the actual effect.
      .then((res) => res.data)
      .then((config) => {
        dispatch(configRetrieved(config));
      });
  };
