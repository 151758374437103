import React, { FunctionComponent } from "react";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router-dom";
import App from "./App";
import AppKeycloak from "./AppKeycloak";
import GetAdfsToken from "./GetAdfsToken";
import GetOpenIdConnectToken from "./GetOpenIdConnectToken";
import AdfsLogin from "./AdfsLogin";
import OpenIdConnectLogin from "./OpenIdConnectLogin";
import Logout from "./Logout";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ConfigWrapper from "../components/ConfigWrapper";
import GtmInit from "../components/GtmInit";
import { AnyAction, Store } from "redux";
import { RootState } from "../reducers/types";
import KeycloakWrapper from "./KeycloakWrapper";
import TotpTokenManagement from "./TotpTokenManagement";
import PasswordReset from "./PasswordReset";

const Root: FunctionComponent<{ store: Store<RootState, AnyAction> }> = ({
  store,
}) => {
  return (
    <Provider store={store}>
      <ConfigWrapper>
        <KeycloakWrapper>
          <GtmInit>
            <div className="viewport">
              <Header />
              <Switch>
                <Route exact path="/" component={App} />
                <Route exact path="/Keycloak" component={AppKeycloak} />
                <Route path="/AdfsLogin" component={AdfsLogin} />
                <Route path="/GetToken" component={GetAdfsToken} />
                <Route
                  path="/OpenIdConnectLogin"
                  component={OpenIdConnectLogin}
                />
                <Route
                  path="/GetOpenIdConnectToken"
                  component={GetOpenIdConnectToken}
                />
                <Route
                  path="/TotpTokenManagement"
                  component={TotpTokenManagement}
                />
                <Route path="/Logout" component={Logout} />
                <Route path="/PasswordReset" component={PasswordReset} />
              </Switch>
              <Footer />
            </div>
          </GtmInit>
        </KeycloakWrapper>
      </ConfigWrapper>
    </Provider>
  );
};

export default Root;
