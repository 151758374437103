import { Dispatch } from "redux";
import { RootState } from "../reducers/types";
import axios from "../utils/api";
import {
  PASSWORD_RESET_DONE,
  PASSWORD_RESET_INPROGRESS,
  PASSWORD_RESET_ERROR,
} from "../constants/actionTypes";
import { pwdErrorCodes } from "../constants/pwdErrorCodes";
import { AxiosResponse } from "axios";

export const passwordReset = () => {
  return {
    type: PASSWORD_RESET_DONE,
  };
};

export const passwordResetting = () => {
  return {
    type: PASSWORD_RESET_INPROGRESS,
  };
};

export const passwordResetError = (
  error: any,
  errerResponse: AxiosResponse | null,
  errorCodes: string[]
) => {
  return {
    type: PASSWORD_RESET_ERROR,
    payload: { error, errerResponse, errorCodes },
  };
};

export const thunkedPasswordReset = (
  newPassword: string,
  repeatPassword: string,
  username: string,
  verificationNumber: string
) =>
  // action creator, when invoked…
  (
    dispatch: Dispatch, // …returns thunk; when invoked with `dispatch`…
    getState: () => RootState
  ) => {
    if (newPassword !== repeatPassword) {
      dispatch(passwordResetError(null, null, [pwdErrorCodes.notEqual]));
      return;
    }

    dispatch(passwordResetting());
    const accessToken = getState().authentication.token?.accessToken;
    const config = getState().config;
    const url = `${config.api}${config.endPoints.user.resetPassword}`;

    axios
      .request({
        url,
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: { newPassword, username, verificationNumber },
      }) // …performs the actual effect.
      .then((res) => {
        if (res.status === 200) {
          dispatch(passwordReset());
        }
      })
      .catch((err) => {
        // The ErrorCodes, returned by the server
        const errorCodes: string[] = err?.response?.data?.errorCodes;
        dispatch(passwordResetError(err, err.response, errorCodes));
      });
  };
