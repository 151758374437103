import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../reducers/types";
import { thunkedUserRetrieved } from "../actions/getUser";

export default () => {
  const dispatch = useDispatch();
  const authentication = useSelector(
    (state: RootState) => state.authentication
  );
  const userInformation = useSelector((state: RootState) => state.user);
  const user = userInformation.user;
  const [visible, setVisible] = useState(false);

  const hoverUsername = () => setVisible(true);

  const hideUsername = () => setVisible(false);

  if (
    authentication.loggedIn &&
    !userInformation.isLoading &&
    !userInformation.error &&
    !userInformation.user
  ) {
    dispatch(thunkedUserRetrieved());
  }

  return (
    <div className="header">
      <div className="full-container">
        <div className="logo">
          <Link className="logo" to="/"></Link>
        </div>
        {authentication.loggedIn && (
          <>
            <div className="menu float-right">
              <button onMouseOver={hoverUsername} onMouseOut={hideUsername}>
                <div className="user-icon margin-right"></div>
                {(user && user.name) || (user && user.nameIdentifier)}
              </button>
            </div>
            <div
              className={`menu expandable-menu ${visible ? "visible" : ""}`}
              onMouseOver={hoverUsername}
              onMouseOut={hideUsername}
            >
              <ul>
                <li>
                  <Link to="/TotpTokenManagement">Token beheer</Link>
                </li>
                <li>
                  <Link to="/Logout">Log uit</Link>
                </li>
              </ul>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
