import React, { FunctionComponent, useEffect, useRef, useContext } from "react";
import { useSelector } from "react-redux";
import { isTokenStillValid } from "../utils/auth";
import { setInterval } from "timers";
import { RootState } from "../reducers/types";
import { Redirect, RouteComponentProps } from "react-router-dom";
import ConfigContext from "./ConfigContext";

const TIMEOUT_DELAY = 1000;

interface AdfsAuthWrapperProps extends RouteComponentProps {
  isTotp?: boolean;
}

const AdfsAuthWrapper: FunctionComponent<AdfsAuthWrapperProps> = ({
  history,
  location,
  children,
  isTotp,
}) => {
  const timeoutID = useRef<NodeJS.Timeout>();
  const config = useContext(ConfigContext);

  const authentication = useSelector(
    (state: RootState) => state.authentication
  );

  useEffect(() => {
    const checkActivity = () => {
      if (!authentication.loggedIn) {
        return;
      }

      if (!isTokenStillValid(authentication.token)) {
        history.push("/Logout/");
      }
    };

    // Set-up
    if (authentication.loggedIn) {
      timeoutID.current = setInterval(() => {
        checkActivity();
      }, TIMEOUT_DELAY);
    } else if (timeoutID.current !== undefined) {
      clearInterval(timeoutID.current);
    }

    return () => {
      // Clean-up
      if (timeoutID.current !== undefined) {
        clearInterval(timeoutID.current);
      }
    };
  }, [authentication.loggedIn, authentication.token, history]);

  const originalPath = location.pathname.replace(config.basePath, "");

  return (
    <>
      {!authentication.loggedIn ? (
        <Redirect
          to={{
            pathname: "/AdfsLogin",
            state: { originalPath, originalSearch: location.search, isTotp },
          }}
        />
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export { AdfsAuthWrapper as default };
