import React, { FunctionComponent, useState, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import AdfsAuthWrapper from "../components/AdfsAuthWrapper";
import ContentContainer from "../components/ContentContainer";
import { useDispatch, useSelector } from "react-redux";
import { thunkedSecretGenerated } from "../actions/generateSecret";
import { thunkedVerificationNumberSent } from "../actions/sendVerificationNumber";
import { thunkedVerificationNumberValidated } from "../actions/validateVerificationNumber";
import {
  AuthenticationMethod,
  RootState,
  TotpInformation,
  User,
} from "../reducers/types";
import { thunkedTotpNumberValidated } from "../actions/validateTotpNumber";
import { parse } from "qs";
import { thunkedHasActiveToken } from "../actions/hasActiveToken";
import { confirmTotpOverwrite } from "../actions/confirmTotpOverwrite";
import { totpTokenManagementInputChanged } from "../actions/totpTokenManagementInputChanged";
import { tokenManagementErrorCodes } from "../constants/tokenManagementErrorCodes";
import { useTranslation } from "react-i18next";

const succesRedirectTimeout = 5000;

const hasError = (errorCode: string, error: any) => {
  const errorCodes: string[] =
    error && error.errorCodes ? error.errorCodes : [];
  if (!errorCodes || !errorCodes.includes) {
    return false;
  }

  return errorCodes.includes(errorCode);
};

const hasPhoneNumber = (userInformation: User): boolean => {
  return userInformation.availableAuthenticationMethods.some(
    (_) => _ === AuthenticationMethod.Phone
  );
};

const Step0: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const handleClickConfirmOverwriteToken = () => {
    dispatch(confirmTotpOverwrite());
  };

  return (
    <>
      <div>
        {t("totp.step0.hasExistingToken")}
        <br />
        {t("totp.step0.continueWithReplacement")}
      </div>
      <button
        className="btn btn-default wide"
        onClick={() => handleClickConfirmOverwriteToken()}
      >
        {t("totp.step0.continue")}
      </button>
    </>
  );
};

const Step1: FunctionComponent<{
  totpInformation: TotpInformation;
  error: any;
}> = ({ totpInformation, error }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector((state: RootState) => state.user).user;

  const handleClickSendVerificationNumber = () => {
    dispatch(thunkedVerificationNumberSent());
  };

  if (user === null) {
    return <></>;
  }

  return (
    <>
      {hasPhoneNumber(user) ? (
        <div>{t("totp.step1.smsVerificationText")}</div>
      ) : (
        <div>{t("totp.step1.mailVerificationText")}</div>
      )}
      <div>
        {hasError(tokenManagementErrorCodes.noEmailaddress, error) && (
          <div className="error">
            {t("totp.step1.noMailError", {
              contactInfo: t(
                user.isInternal
                  ? "totp.step1.internalContactInfo"
                  : "totp.step1.externalContactInfo"
              ),
            })}
          </div>
        )}
        {hasError(tokenManagementErrorCodes.noPhoneNumber, error) && (
          <div className="error">
            {t("totp.step1.noSmsError", {
              contactInfo: t(
                user.isInternal
                  ? "totp.step1.internalContactInfo"
                  : "totp.step1.externalContactInfo"
              ),
            })}
          </div>
        )}
        <button
          className="btn btn-default wide"
          onClick={() => handleClickSendVerificationNumber()}
        >
          {t("totp.step1.sendVerivicationNumber")}
        </button>
      </div>
      {totpInformation.verificationNumberSentSuccessfull && (
        <>
          {hasPhoneNumber(user) ? (
            <div>{t("totp.step1.smsSend")}</div>
          ) : (
            <div>{t("totp.step1.mailSend")}</div>
          )}
        </>
      )}
    </>
  );
};

const Step2: FunctionComponent<{
  verificationNumber: string;
  setVerificationNumber: React.Dispatch<React.SetStateAction<string>>;
  error: any;
}> = ({ verificationNumber, setVerificationNumber, error }) => {
  const user = useSelector((state: RootState) => state.user).user;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleClickValidateVerificationNumber = () => {
    dispatch(thunkedVerificationNumberValidated(verificationNumber));
  };

  const handleChangeVerificationNumber = (value: string) => {
    setVerificationNumber(value);
    dispatch(totpTokenManagementInputChanged());
  };

  if (user === null) {
    return <></>;
  }

  return (
    <>
      <div>
        {hasPhoneNumber(user) ? (
          <p>{t("totp.step2.fillSendSms")}</p>
        ) : (
          <p>{t("totp.step2.fillSendEmail")}</p>
        )}
      </div>
      <div>
        <input
          className={`${
            hasError(tokenManagementErrorCodes.verificationNotCorrect, error)
              ? "input-validation-error"
              : ""
          } textBox`}
          type="number"
          value={verificationNumber?.toString()}
          onChange={(event) =>
            handleChangeVerificationNumber(event.currentTarget.value)
          }
        ></input>
        {hasError(tokenManagementErrorCodes.verificationNotCorrect, error) && (
          <div className="error">{t("totp.step2.incorrectNumber")}</div>
        )}
        <button
          className="btn btn-default wide"
          onClick={() => handleClickValidateVerificationNumber()}
        >
          {t("totp.step2.validateNumber")}
        </button>
      </div>
    </>
  );
};

const Step3: FunctionComponent<{
  totpNumber: string;
  setTotpNumber: React.Dispatch<React.SetStateAction<string>>;
  totpInformation: TotpInformation;
  error: any;
}> = ({ totpNumber, setTotpNumber, totpInformation, error }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const handleClickGenerateSecret = () => {
    dispatch(thunkedSecretGenerated());
  };

  const handleClickValidateTotpNumber = () => {
    dispatch(thunkedTotpNumberValidated(totpNumber));
  };

  const handleChangeTotpNumber = (value: string) => {
    setTotpNumber(value);
    dispatch(totpTokenManagementInputChanged());
  };

  return (
    <>
      {!totpInformation.totpNumberValidated && (
        <>
          <div>{t("totp.step3.generateQrCodeExplanation")}</div>
          {totpInformation.qrCodeLoading && (
            <div>{t("totp.step3.loading")}</div>
          )}
          {totpInformation.qrCode && (
            <div>
              <img
                className="qr-code"
                alt="QR-code to be scanned with an authenticator app"
                width="250"
                src={`data:image/png;base64,${totpInformation.qrCode}`}
              />
            </div>
          )}
          {!totpInformation.qrCode && (
            <div>
              <button
                className="btn btn-default wide"
                onClick={() => handleClickGenerateSecret()}
              >
                {t("totp.step3.generateQrCode")}
              </button>
            </div>
          )}
          {totpInformation.qrCode && (
            <>
              <div>{t("totp.step3.fillInControlCode")}</div>
              <div>
                <input
                  className={`${
                    hasError(
                      tokenManagementErrorCodes.totpNumberIncorrect,
                      error
                    )
                      ? "input-validation-error"
                      : ""
                  } textBox`}
                  type="number"
                  value={totpNumber?.toString()}
                  onChange={(event) =>
                    handleChangeTotpNumber(event.currentTarget.value)
                  }
                ></input>
                {hasError(
                  tokenManagementErrorCodes.totpNumberIncorrect,
                  error
                ) && (
                  <div className="error">
                    {t("totp.step3.controlCodeNotCorrect")}
                  </div>
                )}
                <button
                  className="btn btn-default wide"
                  onClick={() => handleClickValidateTotpNumber()}
                >
                  {t("totp.step3.validateControlCode")}
                </button>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

const Step4: FunctionComponent<{
  redirectUri: string;
}> = ({ redirectUri }) => {
  const { t } = useTranslation();
  return (
    <div>
      <p className="info">{t("totp.step4.tokenActivated")}</p>
      {redirectUri !== undefined && (
        <p>
          <br />
          {t("totp.step4.redirecting")}
        </p>
      )}
    </div>
  );
};

const InnerTotpTokenManagement: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const totpInformation = useSelector(
    (state: RootState) => state.totpInformation
  );
  const error = useSelector((state: RootState) => state.totpInformation.error);

  const showGenericError = error && error.showGenericError;

  const qs = parse(window.location.search, { ignoreQueryPrefix: true });
  const redirectUri = qs.redirectUri as string;
  useEffect(() => {
    if (totpInformation.flowStep === 4 && redirectUri !== undefined) {
      setTimeout(() => {
        window.location.href = redirectUri;
      }, succesRedirectTimeout);
    }
  });

  const [verificationNumber, setVerificationNumber] = useState<string>("");
  const [totpNumber, setTotpNumber] = useState<string>("");

  if (
    totpInformation.hasActiveToken === null &&
    !totpInformation.hasActiveTokenLoading &&
    !totpInformation.hasActiveTokenError
  ) {
    dispatch(thunkedHasActiveToken());
  }

  return (
    <>
      <ContentContainer>
        <div className="home-container row">
          <div className="col-md-8 col-md-offset-2 message-container center-content">
            <h1>{t("totp.header")}</h1>
            {showGenericError && (
              <div className="error">
                {t("totp.genericError")}
                <br />
                {t("totp.tryAgainLater")}
              </div>
            )}
            {totpInformation.flowStep === 0 && <Step0 />}
            {totpInformation.flowStep === 1 && (
              <Step1 totpInformation={totpInformation} error={error} />
            )}
            {totpInformation.flowStep === 2 && (
              <Step2
                verificationNumber={verificationNumber}
                setVerificationNumber={setVerificationNumber}
                error={error}
              />
            )}
            {totpInformation.flowStep === 3 && (
              <Step3
                totpNumber={totpNumber}
                setTotpNumber={setTotpNumber}
                totpInformation={totpInformation}
                error={error}
              />
            )}
            {totpInformation.flowStep === 4 && (
              <Step4 redirectUri={redirectUri} />
            )}
          </div>
        </div>
      </ContentContainer>
    </>
  );
};

const TotpTokenManagement: FunctionComponent<RouteComponentProps> = (props) => {
  return (
    <>
      <AdfsAuthWrapper {...props} isTotp={true}>
        <InnerTotpTokenManagement />
      </AdfsAuthWrapper>
    </>
  );
};

export default TotpTokenManagement;
