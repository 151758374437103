import React, { FunctionComponent } from "react";

const ContentContainer: FunctionComponent = ({ children }) => {
  return (
    <>
      <div className="content">
        <div className="container">{children}</div>
      </div>
    </>
  );
};

export default ContentContainer;
