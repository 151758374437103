import React, { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Root from "./containers/Root";
import { I18nProvider } from "./i18n";
import configureStore from "./store/configureStore";

export default () => {
  const store = configureStore();

  const documentHead = document.head.getElementsByTagName("base")[0].href;
  const basePath = documentHead.replace(document.location.origin, "");

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <I18nProvider>
        <Router basename={basePath}>
          <Root store={store} />
        </Router>
      </I18nProvider>
    </Suspense>
  );
};
