import React, { FunctionComponent, useState, useEffect } from "react";
import TextBox from "../components/shared/Textbox";
import PasswordInput from "../components/shared/PasswordInput";
import { useDispatch, useSelector } from "react-redux";
import ContentContainer from "../components/ContentContainer";
import { RootState } from "../reducers/types";
import { thunkedPasswordReset } from "../actions/resetPassword";
import { RouteComponentProps } from "react-router-dom";
import { pwdErrorCodes } from "../constants/pwdErrorCodes";
import { thunkedVerificationNumberSentByUsername } from "../actions/sendVerificationNumberByUsername";
import { thunkedVerificationNumberByUsernameValidated } from "../actions/validateVerificationNumberByUsername";
import { parse } from "qs";
import { passwordResetInputChange } from "../actions/passwordResetInputChange";
import { useTranslation } from "react-i18next";

const inputValidatErrorCssClass = "input-validation-error";
const succesRedirectTimeout = 5000;

const hasError = (errorCode: string, error: any) => {
  const errorCodes: string[] =
    error && error.errorCodes ? error.errorCodes : [];

  if (!errorCodes || !errorCodes.includes) {
    return false;
  }

  return errorCodes.includes(errorCode);
};

const Step0: FunctionComponent<{
  username: string;
  setUsername: React.Dispatch<React.SetStateAction<string>>;
  isInternal: boolean | null;
  error: any;
}> = ({ username, setUsername, isInternal, error }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleChangeUsername = (value: any) => {
    setUsername(value);
    dispatch(passwordResetInputChange());
  };

  const handleClickLogIn = () => {
    dispatch(thunkedVerificationNumberSentByUsername(username));
  };

  return (
    <>
      <div>
        <p>{t("passwordReset.step0.enterUsername")}</p>
        <TextBox
          className={
            hasError(pwdErrorCodes.userNotFound, error)
              ? inputValidatErrorCssClass
              : ""
          }
          onChange={(value) => {
            handleChangeUsername(value);
          }}
          value={username ? username : null}
        />
        {hasError(pwdErrorCodes.noEmailaddress, error) && (
          <div className="error">
            {t("passwordReset.step0.noMailError", {
              contactInfo: t(
                isInternal
                  ? "passwordReset.step0.internalContactInfo"
                  : "passwordReset.step0.externalContactInfo"
              ),
            })}
          </div>
        )}
        {hasError(pwdErrorCodes.noPhoneNumber, error) && (
          <div className="error">
            {t("passwordReset.step0.noSmsError", {
              contactInfo: t(
                isInternal
                  ? "passwordReset.step0.internalContactInfo"
                  : "passwordReset.step0.externalContactInfo"
              ),
            })}
          </div>
        )}
        {hasError(pwdErrorCodes.userNotFound, error) && (
          <div className="error">{t("passwordReset.step0.noUserError")}</div>
        )}
        <button className="btn btn-default" onClick={() => handleClickLogIn()}>
          {t("passwordReset.step0.startReset")}
        </button>
      </div>
    </>
  );
};

const Step1: FunctionComponent<{
  username: string;
  verificationNumber: string;
  isInternal: boolean | null;
  setVerificationNumber: React.Dispatch<React.SetStateAction<string>>;
  error: any;
}> = ({
  username,
  verificationNumber,
  setVerificationNumber,
  isInternal,
  error,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleChangeVerificationNumber = (value: string) => {
    setVerificationNumber(value);
    dispatch(passwordResetInputChange());
  };

  const handleClickValidateVerificationNumber = () => {
    dispatch(
      thunkedVerificationNumberByUsernameValidated(username, verificationNumber)
    );
  };

  return (
    <>
      <div>
        {isInternal ? (
          <p>{t("passwordReset.step1.smsSend")}</p>
        ) : (
          <p>{t("passwordReset.step1.mailSend")}</p>
        )}
        <p>{t("passwordReset.step1.enterVerfificationNumber")}</p>
      </div>
      <div>
        <input
          className={`${
            hasError(pwdErrorCodes.verificationNotCorrect, error)
              ? inputValidatErrorCssClass
              : ""
          } textBox
         `}
          type="number"
          value={verificationNumber?.toString()}
          onChange={(event) =>
            handleChangeVerificationNumber(event.currentTarget.value)
          }
        />
        {hasError(pwdErrorCodes.verificationNotCorrect, error) && (
          <div className="error">
            {t("passwordReset.step1.enterVerfificationNumber")}
          </div>
        )}
        <button
          className="btn btn-default"
          onClick={() => handleClickValidateVerificationNumber()}
        >
          {t("passwordReset.step1.validateNumber")}
        </button>
      </div>
    </>
  );
};

const Step2: FunctionComponent<{
  username: string;
  newPassword: string;
  setNewPassword: React.Dispatch<React.SetStateAction<string>>;
  repeatPassword: string;
  setRepeatPassword: React.Dispatch<React.SetStateAction<string>>;
  verificationNumber: string;
  error: any;
}> = ({
  username,
  newPassword,
  setNewPassword,
  repeatPassword,
  setRepeatPassword,
  verificationNumber,
  error,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleChangePassword = (value: any) => {
    dispatch(passwordResetInputChange());
    setNewPassword(value);
  };

  const handleChangeRepeatPassword = (value: any) => {
    dispatch(passwordResetInputChange());
    setRepeatPassword(value);
  };

  const handleClickResetPassword = () => {
    dispatch(
      thunkedPasswordReset(
        newPassword,
        repeatPassword,
        username,
        verificationNumber
      )
    );
  };
  return (
    <>
      {hasError(pwdErrorCodes.userNotFound, error) && (
        <div className="error">{t("passwordReset.step2.userNotFound")}</div>
      )}
      {hasError(pwdErrorCodes.generic, error) && (
        <div className="error">
          {t("passwordReset.step2.errorSavingPassword")}
        </div>
      )}
      {t("passwordReset.step2.enterNewPassword")}
      <div>
        <PasswordInput
          onChange={(value) => {
            handleChangePassword(value);
          }}
          className={
            hasError(pwdErrorCodes.notValid, error) ||
            hasError(pwdErrorCodes.usedBefore, error)
              ? inputValidatErrorCssClass
              : ""
          }
          value={newPassword}
        />
        {hasError(pwdErrorCodes.notValid, error) && (
          <div className="error">
            {t("passwordReset.step2.errorRequirements")}
          </div>
        )}
        {hasError(pwdErrorCodes.usedBefore, error) && (
          <div className="error">
            {t("passwordReset.step2.errorUsedBefore")}
          </div>
        )}
      </div>
      {t("passwordReset.step2.enterNewPasswordConfirm")}
      <div>
        <PasswordInput
          onChange={(value) => handleChangeRepeatPassword(value)}
          className={
            hasError(pwdErrorCodes.notEqual, error)
              ? inputValidatErrorCssClass
              : ""
          }
          value={repeatPassword}
        />
        {hasError(pwdErrorCodes.notEqual, error) && (
          <div className="error">
            {t("passwordReset.step2.errorPasswordsNotEqual")}
          </div>
        )}
      </div>
      <div>
        <button
          onClick={() => handleClickResetPassword()}
          className="btn btn-default"
        >
          {t("passwordReset.step2.confirmChange")}
        </button>
      </div>
      <div>
        <p>{t("passwordReset.step2.passwordRequirements")}</p>
        <p>
          <ul className="list">
            <li>{t("passwordReset.step2.passwordRequirementMinCharacters")}</li>
            <li>{t("passwordReset.step2.passwordRequirementMinCapitals")}</li>
            <li>{t("passwordReset.step2.passwordRequirementMinNumerics")}</li>
            <li>{t("passwordReset.step2.passwordRequirementNoSpaces")}</li>            
            <li>{t("passwordReset.step2.passwordRequirementNoCompanyName")}</li>
            <li>{t("passwordReset.step2.passwordRequirementNoReuse")}</li>
            <li>{t("passwordReset.step2.passwordRequirementNoIncrement")}</li>
          </ul>
        </p>
      </div>
    </>
  );
};

const Step3: FunctionComponent<{
  redirectUri?: string;
}> = ({ redirectUri }) => {
  const { t } = useTranslation();

  return (
    <>
      <p className="info">{t("passwordReset.step3.changeSuccessful")}</p>
      {redirectUri !== undefined && (
        <p>
          <a href={redirectUri}>{t("passwordReset.step3.clickToLogin")}</a>
          <br />
          {t("passwordReset.step3.forwardToLogin")}
        </p>
      )}
    </>
  );
};

const PasswordReset: FunctionComponent<RouteComponentProps> = () => {
  const { t } = useTranslation();
  const resetState = useSelector(
    (state: RootState) => state.passwordResetInformation
  );
  const error = useSelector(
    (state: RootState) => state.passwordResetInformation.error
  );

  const showGenericError = error && error.showGenericError;

  const [verificationNumber, setVerificationNumber] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [repeatPassword, setRepeatPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");

  const qs = parse(window.location.search, { ignoreQueryPrefix: true });
  const redirectUri = qs.redirectUri as string;
  useEffect(() => {
    if (resetState.flowStep === 3 && redirectUri !== undefined) {
      setTimeout(() => {
        window.location.href = redirectUri;
      }, succesRedirectTimeout);
    }
  });

  return (
    <>
      <ContentContainer>
        <div className="home-container row">
          <div className="col-md-8 col-md-offset-2 message-container center-content">
            <h1>{t("passwordReset.header")}</h1>
            {showGenericError && (
              <div className="error">
                {t("passwordReset.genericError")}
                <br />
                {t("passwordReset.tryAgainLater")}
              </div>
            )}
            {resetState.flowStep === 0 && (
              <Step0
                username={username}
                setUsername={setUsername}
                isInternal={resetState.isInternal}
                error={error}
              />
            )}
            {resetState.flowStep === 1 && (
              <Step1
                username={username}
                verificationNumber={verificationNumber}
                setVerificationNumber={setVerificationNumber}
                isInternal={resetState.isInternal}
                error={error}
              />
            )}
            {resetState.flowStep === 2 && (
              <Step2
                username={username}
                newPassword={newPassword}
                setNewPassword={setNewPassword}
                setRepeatPassword={setRepeatPassword}
                repeatPassword={repeatPassword}
                verificationNumber={verificationNumber}
                error={error}
              />
            )}
            {resetState.flowStep === 3 && <Step3 redirectUri={redirectUri} />}
          </div>
        </div>
      </ContentContainer>
    </>
  );
};

export default PasswordReset;
