export interface Authentication {
  loggedIn: boolean;
  token?: Token;
}

export interface Token {
  accessToken: string;
  accessTokenExpiresAt: number;
  idToken: string;
  refreshToken?: string;
  refreshTokenExpiresAt?: number;
}

export interface Tile {
  name: string;
  uri: string;
}

interface ConfigOAuthValues {
  logOutUrl: string;
  authorizeUrl: string;
  tokenUrl: string;
  clientId: string;
  clientSecret: string;
  resource: string;
  resourceTotp: string;
}

interface ConfigOpenIdConnectValues {
  logOutUrl: string;
  authorizeUrl: string;
  clientId: string;
}

interface googleTagValues {
  gtmId: string;
}

export interface User {
  name: string;
  nameIdentifier: string;
  isInternal: boolean;
  availableAuthenticationMethods: Array<AuthenticationMethod>;
}

export interface TotpInformation {
  hasActiveToken: boolean | null;
  hasActiveTokenLoading: boolean;
  hasActiveTokenError: boolean;
  verificationNumberValidated: boolean;
  qrCode: string | null;
  qrCodeLoading: boolean;
  verificationNumberSentSuccessfull: boolean;
  totpNumberValidated: boolean;
  totpNumberValidating: boolean;
  verificationNumberSending: boolean;
  secretGenerating: boolean;
  verificationNumberValidating: boolean;
  error: any | null;
  flowStep: number | null;
}

export enum AuthenticationMethod {
  Phone = "Phone",
  Email = "Email",
}

export interface UserInformation {
  user: User | null;
  isLoading: boolean;
  error: any | null;
}

export interface PasswordResetInformation {
  user: User | null;
  error: any | null;
  verificationNumberValidated: boolean;
  verificationNumberValidating: boolean;
  verificationNumberSentSuccessfull: boolean;
  verificationNumberSending: boolean;
  usernameValidated: boolean;
  usernameSent: boolean;
  passwordResetDone: boolean;
  passwordResetting: boolean;
  flowStep: number;
  isInternal: boolean | null;
}

export interface Config {
  basePath: string;
  api: string;
  authProvider: AuthProvider;
  staterOAuthValues: ConfigOAuthValues;
  staterOpenIdConnectValues: ConfigOpenIdConnectValues;
  googleTagManager: googleTagValues;
  isLoaded?: boolean;
  endPoints: {
    tiles: string;
    user: {
      me: string;
      generateSecret: string;
      sendVerificationNumber: string;
      sendVerificationNumberByUsername: string;
      validateVerificationNumber: string;
      validateVerificationNumberByUser: string;
      validateTotpNumber: string;
      validatePassword: string;
      resetPassword: string;
      hasActiveToken: string;
    };
  };
}

export interface TileInformation {
  tiles: Array<Tile>;
  isLoading: boolean;
  isLoaded: boolean;
}

export enum AuthProvider {
  Adfs = "ADFS",
  Keycloak = "Keycloak",
}

export enum KeycloakInitState {
  NotInitialized = "NotInitialized",
  Initializing = "Initializing",
  Initialized = "Initialized",
}

export interface KeycloakState {
  state: KeycloakInitState;
}

export interface RootState {
  authentication: Authentication;
  user: UserInformation;
  keycloak: KeycloakState;
  config: Config;
  tileInformation: TileInformation;
  totpInformation: TotpInformation;
  passwordResetInformation: PasswordResetInformation;
}
