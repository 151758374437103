import { Dispatch } from "redux";
import { RootState } from "../reducers/types";
import axios from "../utils/api";
import {
  HAS_ACTIVE_TOKEN,
  HAS_ACTIVE_TOKEN_LOADING,
  HAS_ACTIVE_TOKEN_ERROR,
} from "../constants/actionTypes";
import { AxiosResponse } from "axios";

interface HasActiveTokenResponse {
  hasToken: boolean;
}

export const hasActiveToken = (hasToken: boolean) => {
  return {
    type: HAS_ACTIVE_TOKEN,
    payload: { hasToken },
  };
};

export const hasActiveTokenLoading = () => {
  return {
    type: HAS_ACTIVE_TOKEN_LOADING,
  };
};

export const hasActiveTokenError = (
  error: any,
  errorResponse: AxiosResponse
) => {
  return {
    type: HAS_ACTIVE_TOKEN_ERROR,
    payload: { error, errorResponse },
  };
};

export const thunkedHasActiveToken = () =>
  // action creator, when invoked…
  (
    dispatch: Dispatch, // …returns thunk; when invoked with `dispatch`…
    getState: () => RootState
  ) => {
    dispatch(hasActiveTokenLoading());
    const accessToken = getState().authentication.token?.accessToken;
    const config = getState().config;
    const url = `${config.api}${config.endPoints.user.hasActiveToken}`;

    axios
      .request<HasActiveTokenResponse>({
        url,
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }) // …performs the actual effect.
      .then((res) => {
        if (res.status === 200) {
          dispatch(hasActiveToken(res.data.hasToken));
        }
      })
      .catch((err) => {
        dispatch(hasActiveTokenError(err, err.response));
      });
  };
