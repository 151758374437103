import { createStore, applyMiddleware, PreloadedState } from "redux";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "../reducers";
import { RootState } from "../reducers/types";
import { loadAuthState, saveState } from "./localStorage";

const configureStore = (preloadedState?: PreloadedState<RootState>) => {
  const restoredState = {
    ...preloadedState,
    authentication: loadAuthState(),
  };

  const store = createStore(
    rootReducer,
    restoredState,
    composeWithDevTools(applyMiddleware(thunk, createLogger()))
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept("../reducers", () => {
      store.replaceReducer(rootReducer);
    });
  }

  store.subscribe(() => saveState(store.getState()));

  return store;
};

export default configureStore;
