import React, { FC } from 'react';
import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next, I18nextProvider } from 'react-i18next';

i18n
  // load translations using http
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next
  .use(initReactI18next)
  // init i18next
  .init({
    backend: {
      loadPath: `./locales/{{lng}}.json`,
      requestOptions: {
        cache: 'no-cache',
      },
    },
    whitelist: ['nl'],
    fallbackLng: 'nl',
    interpolation: {
      escapeValue: false,
    },
  });

export const I18nProvider: FC = ({ children }) => (
  <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
);

export default i18n;
