import React from "react";

interface InputProps {
  onBlur?(value: string | null): void;
  onChange?(value: string | null): void;
  type?: string;
  value?: string | string[] | number | null;
  disabled?: boolean | null;
  className: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ onBlur, onChange, type, value, className, disabled, ...props }, ref) => {
    const handleBlur = (value: string): void => {
      if (onBlur) {
        onBlur(value !== "" ? value : null);
      }
    };

    const handleChange = (value: string): void => {
      if (onChange) {
        onChange(value !== "" ? value : null);
      }
    };

    return (
      <>
        <input
          {...props}
          ref={ref}
          onBlur={(event) => handleBlur(event.target.value)}
          onChange={(event) => handleChange(event.target.value)}
          type={type}
          value={value === null ? "" : value}
          className={className}
        />
      </>
    );
  }
);

export default Input;
