import { Dispatch } from "redux";
import { RootState } from "../reducers/types";
import axios from "../utils/api";
import {
  TOTP_NUMBER_VALIDATED,
  TOTP_NUMBER_ERROR,
  TOTP_NUMBER_VALIDATING,
} from "../constants/actionTypes";
import { AxiosResponse } from "axios";

const totpNumberValidated = () => {
  return {
    type: TOTP_NUMBER_VALIDATED,
  };
};

const totpNumberValidating = () => {
  return {
    type: TOTP_NUMBER_VALIDATING,
  };
};

const totpNumberValidatingError = (
  error: any,
  errorResponse: AxiosResponse
) => {
  return {
    type: TOTP_NUMBER_ERROR,
    payload: { error, errorResponse },
  };
};

export const thunkedTotpNumberValidated = (totpNumber: string) =>
  // action creator, when invoked…
  (
    dispatch: Dispatch, // …returns thunk; when invoked with `dispatch`…
    getState: () => RootState
  ) => {
    dispatch(totpNumberValidating());
    const accessToken = getState().authentication.token?.accessToken;
    const config = getState().config;
    const url = `${config.api}${config.endPoints.user.validateTotpNumber}`;

    axios
      .request({
        url,
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: { totpNumber },
      }) // …performs the actual effect.
      .then((res) => {
        if (res.status === 200) {
          dispatch(totpNumberValidated());
        }
      })
      .catch((err) => {
        dispatch(totpNumberValidatingError(err, err.response));
      });
  };
