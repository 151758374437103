import { Token } from "../reducers/types";

const isTokenStillValid = (token?: Token) => {
  // Check whether the current time is past the
  // access token's expiry time
  if (token === undefined) {
    return false;
  }

  return new Date().getTime() < token.accessTokenExpiresAt;
};

const generateNonce = () => {
  const crypto =
    window.crypto ?? ((window as { [key: string]: any })["msCrypto"] as Crypto);
  const array = crypto.getRandomValues(new Uint32Array(1));
  return array[0].toString(36);
};

export { isTokenStillValid, generateNonce };
