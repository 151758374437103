import { UserInformation } from "./types";
import {
  USER_RETRIEVED,
  USER_LOADING,
  USER_ERROR,
} from "../constants/actionTypes";
import { AnyAction } from "redux";

const initialState: UserInformation = {
  user: null,
  isLoading: false,
  error: null,
};

export default (
  state: UserInformation = initialState,
  action: AnyAction
): UserInformation => {
  switch (action.type) {
    case USER_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case USER_ERROR:
      return {
        ...state,
        error: action.payload.error,
        isLoading: false,
      };
    case USER_RETRIEVED:
      return {
        ...state,
        user: { ...action.payload.user },
        isLoading: false,
      };
    default:
      return state;
  }
};
