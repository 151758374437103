import React, { FunctionComponent } from "react";
import { getKeycloak } from "../utils/keycloack";

const KeycloakAuthWrapper: FunctionComponent = ({ children }) => {
  const keycloak = getKeycloak();

  if (keycloak?.authenticated === false) {
    // Start auth
    keycloak.login();    
  }

  return keycloak?.authenticated ? <>{children}</> : null;
};

export default KeycloakAuthWrapper;
