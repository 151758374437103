/* eslint-disable no-undef */

import React, { useContext, useEffect, FunctionComponent } from "react";
import { buildURL } from "../utils";

import ConfigContext from "../components/ConfigContext";
import { StaticContext } from "react-router";
import { NONCE } from "../constants/localStorageKeys";
import { RouteComponentProps } from "react-router-dom";
import { generateNonce } from "../utils/auth";

const OpenIdConnectLogin: FunctionComponent<RouteComponentProps<
  {},
  StaticContext,
  { currentPath: string }
>> = ({ location }) => {
  const config = useContext(ConfigContext);

  const nonce = generateNonce();
  localStorage.setItem(NONCE, nonce);

  const state = btoa(
    JSON.stringify({
      [nonce]: {
        currentPath: location.state?.currentPath ?? "/",
      },
    })
  );

  const fullUrl = buildURL(config.staterOpenIdConnectValues.authorizeUrl, {
    response_type: "code",
    scope: "openid",
    client_id: config.staterOpenIdConnectValues.clientId,
    state,
    redirect_uri: `${window.location.origin}/GetOpenIdConnectToken`,
  });

  useEffect(() => {
    window.location.href = fullUrl;
  });

  return <div>Redirecting...</div>;
};

export default OpenIdConnectLogin;
