import React from "react";

export default () => {
  return (
    <div className="footer">
      <img src="images/footer-icon.png" alt="Stater logo" className="inline" />
      <p className="inline margin-left">&copy; Stater 2019</p>
      <a href="/" className="inline">Help & Support</a>      
    </div>
  );
};
