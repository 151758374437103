import {
  SEND_VERIFICATION_NUMBER_ERROR,
  SEND_VERIFICATION_NUMBER_SUCCESSFULL,
  SEND_VERIFICATION_NUMBER_INPROGRESS,
} from "../constants/actionTypes";
import { Dispatch } from "redux";
import { RootState } from "../reducers/types";
import axios from "../utils/api";
import { AxiosResponse } from "axios";

const sendingVerificationNumber = () => {
  return {
    type: SEND_VERIFICATION_NUMBER_INPROGRESS,
  };
};
export const verificationNumberSentSuccessfull = () => {
  return {
    type: SEND_VERIFICATION_NUMBER_SUCCESSFULL,
  };
};

export const verificationNumberSentError = (
  error: any,
  errorResponse: AxiosResponse
) => {
  return {
    type: SEND_VERIFICATION_NUMBER_ERROR,
    payload: { error, errorResponse },
  };
};

// Look, no store import!
export const thunkedVerificationNumberSent = () =>
  // action creator, when invoked…
  (
    dispatch: Dispatch, // …returns thunk; when invoked with `dispatch`…
    getState: () => RootState
  ) => {
    dispatch(sendingVerificationNumber());
    const accessToken = getState().authentication.token?.accessToken;
    const config = getState().config;
    const url = `${config.api}${config.endPoints.user.sendVerificationNumber}`;

    axios
      .post(
        url,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          dispatch(verificationNumberSentSuccessfull());
        }
      })
      .catch((err) => {
        dispatch(verificationNumberSentError(err, err.response));
      });
  };
