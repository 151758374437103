import React, { FunctionComponent } from "react";
import KeycloakAuthWrapper from "../components/KeycloakAuthWrapper";
import { RouteComponentProps } from "react-router-dom";
import ContentContainer from "../components/ContentContainer";
import { getKeycloak } from "../utils/keycloack";

const App: FunctionComponent<RouteComponentProps> = () => {
  const keycloak = getKeycloak();

  return (
    <>
      <ContentContainer>
        <dl>
          <dt>authenticated</dt>
          <dd>
            {keycloak?.authenticated === undefined
              ? "undefined"
              : keycloak.authenticated
              ? "true"
              : "false"}
          </dd>

          <dt>userInfo</dt>
          <dd>{JSON.stringify(keycloak?.userInfo)}</dd>

          <dt>idTokenParsed</dt>
          <dd>{JSON.stringify(keycloak?.idTokenParsed)}</dd>

          <dt>tokenParsed</dt>
          <dd>{JSON.stringify(keycloak?.tokenParsed)}</dd>
        </dl>
      </ContentContainer>
    </>
  );
};

const AuthenticatedApp: FunctionComponent<RouteComponentProps> = (props) => {
  return (
    <>
      <KeycloakAuthWrapper {...props}>
        <App {...props} />
      </KeycloakAuthWrapper>
    </>
  );
};

export default AuthenticatedApp;
