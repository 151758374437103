export const TRACK_GOOGLE_TAG_MANAGER_EVENT = "TRACK_GOOGLE_TAG_MANAGER_EVENT";
export const CONFIG_RETRIEVED = "CONFIG_RETRIEVED";
export const TILES_RETRIEVED = "TILES_RETRIEVED";
export const USER_RETRIEVED = "USER_RETRIEVED";
export const TOKEN_RETRIEVED = "TOKEN_RETRIEVED";
export const REFRESH_TOKEN_RETRIEVED = "REFRESH_TOKEN_RETRIEVED";
export const HAS_ACTIVE_TOKEN = "HAS_ACTIVE_TOKEN";
export const HAS_ACTIVE_TOKEN_LOADING = "HAS_ACTIVE_TOKEN_LOADING";
export const HAS_ACTIVE_TOKEN_ERROR = "HAS_ACTIVE_TOKEN_ERROR";
export const USER_LOADING = "USER_LOADING";
export const TILES_LOADING = "TILES_LOADING";
export const USER_ERROR = "USER_ERROR";
export const LOGOUT = "LOGOUT";
export const KEYCLOAK_INIT_START = "KEYCLOAK_INIT_START";
export const KEYCLOAK_INIT_DONE = "KEYCLOAK_INIT_DONE";
export const SECRET_GENERATED = "SECRET_GENERATED";
export const SECRET_GENERATING = "SECRET_GENERATING";
export const SECRET_ERROR = "SECRET_ERROR";
export const VERIFICATION_NUMBER_VALIDATED = "VERIFICATION_NUMBER_VALIDATED";
export const VERIFICATION_NUMBER_VALIDATING = "VERIFICATION_NUMBER_VALIDATING";
export const SEND_VERIFICATION_NUMBER_SUCCESSFULL =
  "SEND_VERIFICATION_NUMBER_SUCCESSFULL";
export const VERIFICATION_NUMBER_ERROR = "VERIFICATION_NUMBER_ERROR";
export const SEND_VERIFICATION_NUMBER_ERROR = "SEND_VERIFICATION_NUMBER_ERROR";
export const TOTP_NUMBER_VALIDATED = "TOTP_NUMBER_VALIDATED";
export const TOTP_NUMBER_VALIDATING = "TOTP_NUMBER_VALIDATING";
export const TOTP_NUMBER_ERROR = "TOTP_NUMBER_ERROR";
export const PASSWORD_VALIDATED = "PASSWORD_VALIDATED";
export const PASSWORD_VALIDATING = "PASSWORD_VALIDATING";
export const PASSWORD_ERROR = "PASSWORD_ERROR";
export const PASSWORD_RESET_DONE = "PASSWORD_RESET_DONE";
export const PASSWORD_RESET_INPROGRESS = "PASSWORD_RESETTING";
export const PASSWORD_RESET_ERROR = "PASSWORD_RESET_ERROR";
export const CONFIRM_TOTP_OVERWRITE = "CONFIRM_TOTP_OVERWRITE";
export const VERIFICATION_NUMBER_BY_USERNAME_SENT_SUCCESSFULL =
  "VERIFICATION_NUMBER_BY_USERNAME_SENT_SUCCESSFULL";
export const VERIFICATION_NUMBER_BY_USERNAME_ERROR =
  "VERIFICATION_NUMBER_BY_USERNAME_ERROR";
export const VERIFICATION_NUMBER_BY_USERNAME_VALIDATED =
  "VERIFICATION_NUMBER_BY_USERNAME_VALIDATED";
export const VERIFICATION_NUMBER_BY_USERNAME_VALIDATING =
  "VERIFICATION_NUMBER_BY_USERNAME_VALIDATING";
export const SEND_VERIFICATION_NUMBER_BY_USERNAME_SUCCESSFULL =
  "SEND_VERIFICATION_NUMBER_BY_USERNAME_SUCCESSFULL";
export const SEND_VERIFICATION_NUMBER_BY_USERNAME_ERROR =
  "SEND_VERIFICATION_NUMBER_BY_USERNAME_ERROR";
export const PASSWORD_RESET_INPUT_CHANGED = "PASSWORD_RESET_INPUT_CHANGED";
export const SEND_VERIFICATION_NUMBER_INPROGRESS =
  "SEND_VERIFICATION_NUMBER_INPROGRESS";
export const SEND_VERIFICATION_NUMBER_BY_USERNAME_INPROGRESS =
  "SEND_VERIFICATION_NUMBER_BY_USERNAME_INPROGRESS";
export const TOTP_TOKEN_MANAGEMENT_INPUT_CHANGED =
  "TOTP_TOKEN_MANAGEMENT_INPUT_CHANGED";
