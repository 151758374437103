import { Tile, TileInformation } from "./types";
import { TILES_RETRIEVED, TILES_LOADING } from "../constants/actionTypes";
import { AnyAction } from "redux";

const initialState: TileInformation = {
  tiles: [],
  isLoading: false,
  isLoaded: false,
};

export default (
  state: TileInformation = initialState,
  action: AnyAction
): TileInformation => {
  switch (action.type) {
    case TILES_LOADING: {
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      };
    }
    case TILES_RETRIEVED: {
      return {
        ...state,
        tiles: [...(action.payload.tiles as Array<Tile>)],
        isLoading: false,
        isLoaded: true,
      };
    }
    default:
      return state;
  }
};
