import qs from "qs";

function buildURL(url: string, params?: any) {
  if (params == null) {
    return url;
  }

  const serializedParams = qs.stringify(params);
  if (!serializedParams) {
    return url;
  }

  return `${url}${url.indexOf("?") < 0 ? "?" : "&"}${serializedParams}`;
}

export { buildURL };
