import { TOKEN_RETRIEVED } from "../constants/actionTypes";
import { Dispatch } from "redux";
import { Token, RootState } from "../reducers/types";
import axios from "../utils/api";

interface TokenResponse {
  access_token: string;
  expires_in: number;
  id_token: string;
  refresh_token?: string;
  refresh_token_expires_in?: number;
}
// in an action creator module:
export const tokenRetrieved = (tokenResponse: TokenResponse) => {
  const token: Token = {
    accessToken: tokenResponse.access_token,
    accessTokenExpiresAt:
      tokenResponse.expires_in * 1000 + new Date().getTime(),
    idToken: tokenResponse.id_token,
    refreshToken: tokenResponse.refresh_token,
    refreshTokenExpiresAt: tokenResponse.refresh_token_expires_in
      ? tokenResponse.refresh_token_expires_in * 1000 + new Date().getTime()
      : undefined,
  };

  return {
    type: TOKEN_RETRIEVED,
    payload: { token },
  };
};

// Look, no store import!

export const thunkedTokenRetrieved =
  (code: string) =>
  // action creator, when invoked…
  (
    dispatch: Dispatch, // …returns thunk; when invoked with `dispatch`…
    getState: () => RootState
  ) => {
    const config = getState().config;
    const url = `${config.staterOAuthValues.tokenUrl}`;

    const redirectUri = `${document.baseURI}GetToken`;

    const formEncodedContent =
      `grant_type=${"authorization_code"}` +
      `&client_id=${config.staterOAuthValues.clientId}` +
      `&code=${encodeURIComponent(code)}` +
      `&resource=${encodeURIComponent(config.staterOAuthValues.resource)}` +
      `&redirect_uri=${encodeURIComponent(redirectUri)}`;

    axios
      .request<TokenResponse>({
        url,
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: formEncodedContent,
      }) // …performs the actual effect.
      .then((res) => res.data)
      .then((token) => {
        dispatch(tokenRetrieved(token));
      });
  };
