import { FunctionComponent, useRef, useContext } from "react";
import ConfigContext from "./ConfigContext";
import TagManager from "react-gtm-module";
import React from "react";

const GtmInit: FunctionComponent = ({ children }) => {
  const initRef = useRef(false);
  const config = useContext(ConfigContext);
  const gtmId = config.googleTagManager.gtmId;

  if (gtmId !== null && initRef.current === false) {
    initRef.current = true;
    const tagManagerArgs = {
      gtmId,
    };

    TagManager.initialize(tagManagerArgs);
  }

  return <>{children}</>;
};

export default GtmInit;
