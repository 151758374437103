import { KeycloakState, KeycloakInitState } from "./types";
import {
  KEYCLOAK_INIT_START,
  KEYCLOAK_INIT_DONE,
} from "../constants/actionTypes";
import { AnyAction } from "redux";

const initialState: KeycloakState = {
  state: KeycloakInitState.NotInitialized,
};

export default (
  state: KeycloakState = initialState,
  action: AnyAction
): KeycloakState => {
  switch (action.type) {
    case KEYCLOAK_INIT_START: {
      return {
        ...state,
        state: KeycloakInitState.Initializing,
      };
    }
    case KEYCLOAK_INIT_DONE: {
      return {
        ...state,
        state: KeycloakInitState.Initialized,
      };
    }
    default:
      return state;
  }
};
