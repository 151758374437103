import React, { FunctionComponent } from "react";
import { parse } from "qs";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { thunkedOpenIdConnectTokenRetrieved } from "../actions/getOpenIdConnectToken";
import { RootState } from "../reducers/types";
import { NONCE } from "../constants/localStorageKeys";

const GetOpenIdConnectToken: FunctionComponent<RouteComponentProps> = () => {
  const qs = parse(window.location.search, { ignoreQueryPrefix: true });

  const code = qs.code;
  const encodedState = qs.state as string;

  const nonce = localStorage.getItem(NONCE) as string;
  const state = JSON.parse(atob(encodedState));
  const currentPath = state[nonce]?.currentPath ?? "/";

  const dispatch = useDispatch();
  const authentication = useSelector(
    (state: RootState) => state.authentication
  );

  if (code && !authentication.token) {
    dispatch(thunkedOpenIdConnectTokenRetrieved(code as string));
  }

  return authentication.loggedIn ? (
    <Redirect to={{ pathname: `${currentPath}`, state: {} }} />
  ) : (
    <div>Redirecting...</div>
  );
};

export default GetOpenIdConnectToken;
