import React from "react";
import Input from "./Input";

interface TextBoxProps {
  onBlur?(value: string | null): void;
  onChange?(value: string | null): void;
  className?: string | null;
  value?: string | string[] | number | null;
  disabled?: boolean | null;
}

const TextBox = React.forwardRef<HTMLInputElement, TextBoxProps>(
  ({ onChange, onBlur, className, value, disabled, ...props }, ref) => {
    className = className + " textBox";
    return (
      <Input
        {...props}
        onChange={onChange}
        onBlur={onBlur}
        type="text"
        ref={ref}
        disabled={disabled}
        value={value}
        className={className}
      />
    );
  }
);

export default TextBox;
