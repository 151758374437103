import { AnyAction } from "redux";
import { AxiosResponse } from "axios";

interface StatusCodeDictionary {
  [key: number]: string;
}
export const HandleServerErrorResponse = (
  action: AnyAction,
  statusCodeDictionary: StatusCodeDictionary
) => {
  let errorCodes = action?.payload?.errorCodes;
  let showGenericError = true;
  const error = action.payload?.error;
  const errorResponse = action.payload?.errorResponse as AxiosResponse;

  if (errorResponse?.status === 500 && errorResponse.data?.error) {
    errorCodes = [errorResponse.data.error];
    showGenericError = false;
  } else if (errorResponse?.status === 404) {
    errorCodes = statusCodeDictionary[404];
    showGenericError = false;
  }

  return {
    error: error,
    showGenericError: showGenericError,
    errorCodes: errorCodes,
  };
};
