import React, { useContext, useEffect, FunctionComponent } from "react";
import { buildURL } from "../utils";

import ConfigContext from "../components/ConfigContext";
import { StaticContext } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import { NONCE } from "../constants/localStorageKeys";
import { generateNonce } from "../utils/auth";

const AdfsLogin: FunctionComponent<
  RouteComponentProps<
    {},
    StaticContext,
    { originalPath: string; originalSearch: string; isTotp: boolean }
  >
> = ({ location }) => {
  const config = useContext(ConfigContext);

  const nonce = generateNonce();
  localStorage.setItem(NONCE, nonce);

  const state = btoa(
    JSON.stringify({
      [nonce]: {
        originalPath: location.state?.originalPath ?? "/",
        originalSearch: location.state?.originalSearch ?? "",
      },
    })
  );

  const redirectUri = `${document.baseURI}GetToken`;

  const fullUrl = buildURL(config.staterOAuthValues.authorizeUrl, {
    client_id: config.staterOAuthValues.clientId,
    client_secret: config.staterOAuthValues.clientSecret,
    redirect_uri: redirectUri,
    response_type: "code",
    resource: location.state?.isTotp
      ? config.staterOAuthValues.resourceTotp
      : config.staterOAuthValues.resource,
    state,
  });

  useEffect(() => {
    window.location.href = fullUrl;
  });

  return <div>Redirecting...</div>;
};

export default AdfsLogin;
