/* eslint-disable no-undef */

import React, { FunctionComponent } from "react";
import { parse } from "qs";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { thunkedTokenRetrieved } from "../actions/getToken";
import { RootState } from "../reducers/types";
import { NONCE } from "../constants/localStorageKeys";

const GetAdfsToken: FunctionComponent<RouteComponentProps> = () => {
  const qs = parse(window.location.search, { ignoreQueryPrefix: true });
  const code = qs.code;
  const encodedState = qs.state as string;

  const nonce = localStorage.getItem(NONCE) as string;
  const state = JSON.parse(atob(encodedState));
  const originalPath = state[nonce].originalPath;
  const originalSearch = state[nonce].originalSearch;

  const dispatch = useDispatch();
  const authentication = useSelector(
    (state: RootState) => state.authentication
  );

  if (code && !authentication.token) {
    dispatch(thunkedTokenRetrieved(code as string));
  }

  return authentication.loggedIn ? (
    <Redirect
      to={{ pathname: `${originalPath}`, search: originalSearch, state: {} }}
    />
  ) : (
    <div>Redirecting...</div>
  );
};

export default GetAdfsToken;
