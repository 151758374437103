import { combineReducers } from "redux";
import authentication from "./authentication";
import tileInformation from "./tileInformation";
import totpInformation from "./totpInformation";
import passwordResetInformation from "./passwordResetInformation";
import keycloak from "./keycloak";
import user from "./user";
import config from "./config";
import { RootState } from "./types";

const rootReducer = combineReducers<RootState>({
  authentication,
  config,
  tileInformation,
  keycloak,
  user,
  totpInformation,
  passwordResetInformation,
});

export default rootReducer;
