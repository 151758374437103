import {
  SECRET_GENERATED,
  SECRET_ERROR,
  SECRET_GENERATING,
} from "../constants/actionTypes";
import { Dispatch } from "redux";
import { RootState } from "../reducers/types";
import axios from "../utils/api";
import { AxiosResponse } from "axios";

interface GenerateSecretResponse {
  content: string;
  mediaType: string;
}

export const secretGenerated = (
  generateSecretResponse: GenerateSecretResponse
) => {
  return {
    type: SECRET_GENERATED,
    payload: generateSecretResponse,
  };
};

export const secretGenerating = () => {
  return {
    type: SECRET_GENERATING,
  };
};

export const secretGenerationError = (
  error: any,
  errorResponse: AxiosResponse
) => {
  return {
    type: SECRET_ERROR,
    payload: { error, errorResponse },
  };
};

// Look, no store import!

export const thunkedSecretGenerated = () =>
  // action creator, when invoked…
  (
    dispatch: Dispatch, // …returns thunk; when invoked with `dispatch`…
    getState: () => RootState
  ) => {
    dispatch(secretGenerating());
    const accessToken = getState().authentication.token?.accessToken;
    const config = getState().config;
    const url = `${config.api}${config.endPoints.user.generateSecret}`;

    axios
      .request<GenerateSecretResponse>({
        url,
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }) // …performs the actual effect.
      .then((res) => {
        dispatch(secretGenerated(res.data));
      })
      .catch((err) => {
        dispatch(secretGenerationError(err, err.response));
      });
  };
