import {
  USER_RETRIEVED,
  USER_LOADING,
  USER_ERROR,
} from "../constants/actionTypes";
import { Dispatch } from "redux";
import { User, RootState } from "../reducers/types";
import axios from "../utils/api";
import { AxiosResponse } from "axios";

enum AuthenticationMethod {
  Phone = "Phone",
  Email = "Email",
}

interface UserResponse {
  name: string;
  nameIdentifier: string;  
  isInternal: boolean;
  availableAuthenticationMethods: Array<AuthenticationMethod>;
}

export const userRetrieved = (userResponse: UserResponse) => {
  const user: User = {
    name: userResponse.name,
    nameIdentifier: userResponse.nameIdentifier,    
    isInternal: userResponse.isInternal,
    availableAuthenticationMethods: userResponse.availableAuthenticationMethods,
  };

  return {
    type: USER_RETRIEVED,
    payload: { user },
  };
};

export const userLoading = () => {
  return {
    type: USER_LOADING,
  };
};

export const userError = (error: any, errorResponse: AxiosResponse) => {
  return {
    type: USER_ERROR,
    payload: { error, errorResponse },
  };
};

// Look, no store import!

export const thunkedUserRetrieved = () =>
  // action creator, when invoked…
  (
    dispatch: Dispatch, // …returns thunk; when invoked with `dispatch`…
    getState: () => RootState
  ) => {
    dispatch(userLoading());
    const accessToken = getState().authentication.token?.accessToken;
    const config = getState().config;
    const url = `${config.api}${config.endPoints.user.me}`;

    axios
      .request<UserResponse>({
        url,
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }) // …performs the actual effect.
      .then((res) => {
        dispatch(userRetrieved(res.data));
      })
      .catch((err) => {
        dispatch(userError(err, err.response));
      });
  };
