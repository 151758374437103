import { Config, AuthProvider } from "./types";
import { CONFIG_RETRIEVED } from "../constants/actionTypes";
import { AnyAction } from "redux";

const initialAuthentication: Config = {
  basePath: "",
  api: "",
  endPoints: {
    tiles: "",
    user: {
      me: "",
      generateSecret: "",
      sendVerificationNumber: "",
      sendVerificationNumberByUsername: "",
      validateVerificationNumber: "",
      validateVerificationNumberByUser: "",
      validateTotpNumber: "",
      validatePassword: "",
      resetPassword: "",
      hasActiveToken: "",
    },
  },
  authProvider: AuthProvider.Adfs,
  staterOAuthValues: {
    logOutUrl: "",
    authorizeUrl: "",
    tokenUrl: "",
    clientId: "",
    clientSecret: "",
    resource: "",
    resourceTotp: "",
  },
  staterOpenIdConnectValues: {
    authorizeUrl: "",
    clientId: "",
    logOutUrl: "",
  },
  googleTagManager: {
    gtmId: "",
  },
};

export default (state = initialAuthentication, action: AnyAction): Config => {
  switch (action.type) {
    case CONFIG_RETRIEVED:
      return { ...action.payload.config, isLoaded: true };
    default:
      return state;
  }
};
